import {
  Dropzone,
  FormLinks,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useContext, useState } from "react";
import { useLocation } from "wouter";

import {
  useCreateUserAccessListEvidenceMutation,
  useUserAccessListQuery,
} from "src/queries";

interface IProps {
  listId: number;
}

const UploadUserAccessListEvidence = ({ listId }: IProps) => {
  const { data: list } = useUserAccessListQuery(listId);
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const { mutateAsync: upload, isPending } =
    useCreateUserAccessListEvidenceMutation(listId);

  const onClick = async () => {
    try {
      await upload(files);
      addToast("Evidence uploaded", "success");
      setLocation(`/user-access-lists/${listId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const onDrop = async (newFiles: FileList) => {
    setFiles(
      [...files, ...newFiles].filter(
        (file) => file.type.includes("image") || file.type === "text/csv",
      ),
    );
  };

  return (
    <>
      <Title
        breadcrumbs={[
          { to: `/systems/${list?.systemId}/`, label: list?.systemName ?? "" },
          {
            to: `/user-access-reviews/${list?.reviewId}/`,
            label: `User Access Review ${list?.reviewId}`,
          },
          {
            to: `/user-access-lists/${list?.id}/`,
            label: `User Access List ${list?.id}`,
          },
          { label: "Upload User Access List Evidence" },
        ]}
        title="Upload User Access List Evidence"
      />
      <Card>
        <CardContent>
          <Dropzone label="Add file(s)" onDrop={onDrop} />
          <List>
            {files.map((file) => (
              <ListItem
                key={file.name}
                secondaryAction={
                  <IconButton
                    aria-label="delete"
                    edge="end"
                    onClick={() =>
                      setFiles(files.filter((checkFile) => checkFile !== file))
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            color="primary"
            disabled={files.length === 0}
            loading={isPending}
            onClick={onClick}
            variant="contained"
          >
            Upload
          </LoadingButton>
          <FormLinks
            links={[{ label: "Back", to: `/user-access-lists/${listId}/` }]}
          />
        </CardActions>
      </Card>
    </>
  );
};

export default UploadUserAccessListEvidence;
