import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { UserAccessReview, newUserAccessReview } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useUserAccessReviewsQuery = () =>
  useQuery<UserAccessReview[]>({
    queryKey: ["userAccessReviews"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/user-access-reviews/", {
        signal,
      });
      return response.data.userAccessReviews.map((data: unknown) =>
        newUserAccessReview(data),
      );
    },
  });

export const useUserAccessReviewQuery = (reviewId: number) =>
  useQuery<UserAccessReview>({
    queryKey: ["userAccessReviews", reviewId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/user-access-reviews/${reviewId}/`, {
        signal,
      });
      return newUserAccessReview(response.data);
    },
  });

export const useSystemsUserAccessReviewsQuery = (systemId: number) =>
  useQuery<UserAccessReview[]>({
    queryKey: ["userAccessReviews", "system", systemId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/user-access-reviews/system/${systemId}/`,
        { signal },
      );
      return response.data.userAccessReviews.map((data: unknown) =>
        newUserAccessReview(data),
      );
    },
  });

export const useCreateUserAccessReviewMutation = (systemId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await axios.post("/v1/user-access-reviews/", { systemId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["userAccessReviews"] });
    },
  });
};

export const useCloseUserAccessReviewMutation = (reviewId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await axios.post(`/v1/user-access-reviews/${reviewId}/close/`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["userAccessReviews"] });
    },
  });
};

export const useAdjustUserAccessReviewMutation = (reviewId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await axios.post(`/v1/user-access-reviews/${reviewId}/adjusted/`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["userAccessReviews"] });
    },
  });
};
