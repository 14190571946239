import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { UsernameMapping, newUsernameMapping } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useUsernameMappingQuery = (mappingId: number) =>
  useQuery<UsernameMapping>({
    queryKey: ["usernameMappings", mappingId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/username-mappings/${mappingId}/`, {
        signal,
      });
      return newUsernameMapping(response.data);
    },
  });

export const useSystemUsernameMappingsQuery = (systemId: number) =>
  useQuery<UsernameMapping[]>({
    queryKey: ["usernameMappings", "system", systemId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/username-mappings/system/${systemId}/`,
        { signal },
      );
      return response.data.usernameMappings.map((data: unknown) =>
        newUsernameMapping(data),
      );
    },
  });

interface IUsernameMapping {
  email: string;
  systemId: number;
  username: string;
}

export const useCreateUsernameMappingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IUsernameMapping) =>
      await axios.post("/v1/username-mappings/", data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["usernameMappings"] });
    },
  });
};
