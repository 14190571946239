interface IProps {
  color?: string;
  height: number;
}

const IconLogo = ({ height, color = "currentColor" }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 0 19.445 21.699"
  >
    <path
      d="M89.147.049c-.3-.288-2.525.8-4.842,1.047s-5.412.4-8.662,3.619c-4.332,4.3-2.109,10.57-1.936,10.123s-1.253-4.822,2.758-8.857,9.976-3.452,9.976-3.452-.45,6.79-3.847,10.092a9.425,9.425,0,0,1-6.018,2.936l.157-.753a15.011,15.011,0,0,1,.434-1.959A6.92,6.92,0,0,1,78,11.124a11.141,11.141,0,0,1,1.223-1.511c.111-.118.22-.244.339-.355.024-.025.279-.256.2-.3a.3.3,0,0,0-.208.018,2.168,2.168,0,0,0-.408.18,4.668,4.668,0,0,0-.563.36,6.923,6.923,0,0,0-.673.573,8.616,8.616,0,0,0-.738.81,10.9,10.9,0,0,0-.757,1.075,13.106,13.106,0,0,0-.732,1.367c-.314.679-.54,1.39-.839,2.07a14.226,14.226,0,0,1-1.08,1.987,2.078,2.078,0,0,1-1.342,1.016,3.4,3.4,0,0,1-.674.085,2.56,2.56,0,0,0-1.295.373A1.682,1.682,0,0,0,70,21.088c.441.7,1.62.711,2.319.469a4.895,4.895,0,0,0,1.969-1.7l.031-.035.069-.09.106-.146.136-.187.159-.219.174-.238.18-.25.18-.253c.06-.081.119-.163.18-.244l.165-.225.144-.2.115-.159.062-.085a.416.416,0,0,1,.06-.077.352.352,0,0,1,.2-.071,1.709,1.709,0,0,1,.18-.01l.071-.005c.026,0,.055-.008.082-.008a14.677,14.677,0,0,0,2.368-.509,11.6,11.6,0,0,0,3.2-1.485,7.616,7.616,0,0,0,1.062-.876c3.914-3.885,4.329-8.447,4.878-10.6S89.446.341,89.146.052"
      transform="translate(-69.766 0)"
      fill={color}
    ></path>
  </svg>
);

export default IconLogo;
