import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { Leaver, newLeaver } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useLeaverQuery = (leaverId: number) =>
  useQuery<Leaver>({
    queryKey: ["leavers", leaverId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/leavers/${leaverId}/`, {
        signal,
      });
      return newLeaver(response.data);
    },
  });

export const useLeaversQuery = () =>
  useQuery<Leaver[]>({
    queryKey: ["leavers"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/leavers/", {
        signal,
      });
      return response.data.leavers.map((data: unknown) => newLeaver(data));
    },
  });

interface IActionData {
  reason: string;
}

export const useActionLeaverMutation = (leaverId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IActionData) =>
      await axios.put(`/v1/leavers/${leaverId}/action/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["leavers"] });
    },
  });
};
