import {
  formatDateTime,
  SkeletonRow,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useSystemReportsQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const Reports = ({ systemId }: IProps) => {
  const { data: reports } = useSystemReportsQuery(systemId);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Filename</TableCell>
              <TableCell>Uploaded</TableCell>
              <TableCell>Uploader</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports?.map((report) => (
              <TableRow key={report.id}>
                <TableCell>
                  <Link href={`/v1/soc-reports/${report.id}/`}>
                    {report.name}
                  </Link>
                </TableCell>
                <TableCell>{formatDateTime(report.timestamp)}</TableCell>
                <TableCell>{report.uploaderEmail}</TableCell>
              </TableRow>
            )) ?? <SkeletonRow cols={3} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Reports;
