import { z } from "zod";

const reportSchema = z.object({
  id: z.number().int().positive(),
  name: z.string(),
  timestamp: z.coerce.date(),
  uploaderEmail: z.string(),
  uploaderId: z.number().int().positive(),
});

export type Report = z.infer<typeof reportSchema>;

export const newReport = (data: unknown): Report => reportSchema.parse(data);
