import { PageWrapper as CuraPageWrapper } from "@curaleaf-international/components";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DvrIcon from "@mui/icons-material/Dvr";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GroupsIcon from "@mui/icons-material/Groups";
import HandymanIcon from "@mui/icons-material/Handyman";
import HelpIcon from "@mui/icons-material/Help";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ReviewsIcon from "@mui/icons-material/Reviews";
import axios from "axios";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import IconLogo from "src/components/IconLogo";
import Logo from "src/components/Logo";
import RoleExpiryBanner from "src/components/RoleExpiryBanner";
import { StaffRole } from "src/models";
import { useMutation } from "src/query";

interface IProps {
  children: React.ReactNode;
}

const PageWrapper = ({ children }: IProps) => {
  const { member, unauthenticate } = useContext(AuthContext);
  const { mutateAsync: logout } = useMutation({
    mutationFn: async () => {
      await axios.delete("/v1/staff-sessions/");
    },
    onSuccess: () => unauthenticate(),
  });

  const links = [];

  if (
    member?.roles.some((role) =>
      [
        StaffRole.ADMINISTRATOR,
        StaffRole.APPROVER,
        StaffRole.DEVELOPER,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <ReviewsIcon />,
      label: "Access Reviews",
      to: "/user-access-reviews/",
    });
    links.push({
      icon: <AssignmentIcon />,
      label: "Access Requests",
      to: "/access-requests/",
    });
    links.push({
      icon: <ChangeCircleIcon />,
      label: "Change Requests",
      to: "/change-requests/",
    });
  }
  if (
    member?.roles.some((role) =>
      [
        StaffRole.ADMINISTRATOR,
        StaffRole.AUDITOR,
        StaffRole.DEVELOPER,
      ].includes(role),
    )
  ) {
    links.push({
      icon: <ExitToAppIcon />,
      label: "Leavers",
      to: "/leavers/",
    });
    links.push({
      icon: <MoveUpIcon />,
      label: "Movers",
      to: "/movers/",
    });
    links.push({
      icon: <DvrIcon />,
      label: "Systems",
      to: "/systems/",
    });
    links.push({
      icon: <GroupsIcon />,
      label: "Staff",
      to: "/staff/",
    });
    links.push({
      icon: <HandymanIcon />,
      label: "Activity Log",
      to: "/activities/",
    });
    links.push({
      icon: <HelpIcon />,
      label: "Role access",
      to: "/help/endpoint-roles/",
    });
  }
  if (member?.roles.length === 0) {
    links.push({
      icon: <AssignmentIcon />,
      label: "Request Access",
      to: "/access-requests/new/",
    });
    links.push({
      icon: <ChangeCircleIcon />,
      label: "Request a Change",
      to: "/change-requests/new/",
    });
  }

  return (
    <CuraPageWrapper
      authenticated={member !== null && member !== undefined}
      banner={<RoleExpiryBanner />}
      children={children}
      links={links}
      logos={{
        large: <Logo height={36} />,
        small: <IconLogo height={20} />,
      }}
      logout={logout}
      memberEmail={member?.email}
    />
  );
};

export default PageWrapper;
