import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { Report, newReport } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useSystemReportsQuery = (systemId: number) =>
  useQuery<Report[]>({
    queryKey: ["reports", systemId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/reports/system/${systemId}/`, {
        signal,
      });
      return response.data.reports.map((report: unknown) => newReport(report));
    },
  });

export const useCreateReportMutation = (systemId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (fileList: File[]) => {
      const formData = new FormData();
      fileList.forEach((file) => formData.append("file", file));
      await axios.post(`/v1/reports/system/${systemId}/`, formData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["reports", systemId.toString()],
      });
    },
  });
};
