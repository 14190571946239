import {
  Dropzone,
  SubmitButton,
  TextField,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import { useContext, Fragment } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import {
  useCreateUserAccessListMutation,
  useParseUALCSVMutation,
} from "src/queries";

const FormSchema = z.object({
  users: z
    .array(
      z.object({
        roles: z.string().min(1),
        username: z.string().min(1),
      }),
    )
    .min(1),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  reviewId: number;
}

const CreateUserAccessList = ({ reviewId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: create } = useCreateUserAccessListMutation(reviewId);
  const { mutateAsync: parse } = useParseUALCSVMutation();

  const methods = useForm<FormType>({
    defaultValues: { users: [{ roles: "", username: "" }] },
    resolver: zodResolver(FormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "users",
  });

  const values = methods.watch();

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create(
        data.users.map((user) => ({
          roles: user.roles.split(",").map((role) => role.trim()),
          username: user.username,
        })),
      );
      addToast("List created", "success");
      setLocation(`/user-access-reviews/${reviewId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  const onDrop = async (files: FileList) => {
    const result = await parse([...files]);
    const users = result.map((row) => ({
      username: row.username,
      roles: row.roles.join(","),
    }));
    methods.reset({ users });
  };

  return (
    <Card>
      <CardContent sx={{ paddingBottom: 2 }}>
        <Dropzone label="Parse CSV" multiple={false} onDrop={onDrop} />
      </CardContent>
      <Divider />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardContent sx={{ paddingTop: 2 }}>
            <Grid container spacing={1}>
              {fields.map((field, index) => (
                <Fragment key={field.id}>
                  <Grid size={{ xs: 12, sm: 11 }}>
                    <TextField
                      fullWidth
                      label="Username"
                      name={`users[${index}].username`}
                      autoComplete="off"
                      required
                    />
                    <TextField
                      fullWidth
                      helperText="Comma seperated roles please"
                      label="Roles"
                      name={`users[${index}].roles`}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                  <Grid
                    size={{ xs: 2, sm: 1 }}
                    sx={{ display: "flex", justifyContent: "center" }}
                    textAlign="center"
                  >
                    <IconButton
                      disabled={values.users.length <= 1}
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Fragment>
              ))}
              <Grid size={{ xs: 12 }}>
                <Divider variant="middle" />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Button
                  onClick={() =>
                    append({
                      roles: "",
                      username: "",
                    })
                  }
                  sx={{ marginTop: 1 }}
                  type="button"
                  variant="outlined"
                >
                  Add user
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label="Create User Access List" />
          </CardActions>
        </form>
      </FormProvider>
    </Card>
  );
};

export default CreateUserAccessList;
