import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { Mover, newMover } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useMoverQuery = (moverId: number) =>
  useQuery<Mover>({
    queryKey: ["movers", moverId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/movers/${moverId}/`, {
        signal,
      });
      return newMover(response.data);
    },
  });

export const useMoversQuery = () =>
  useQuery<Mover[]>({
    queryKey: ["movers"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/movers/", {
        signal,
      });
      return response.data.movers.map((data: unknown) => newMover(data));
    },
  });

interface IActionData {
  reason: string;
}

export const useActionMoverMutation = (moverId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IActionData) =>
      await axios.put(`/v1/movers/${moverId}/action/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["movers"] });
    },
  });
};
